import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import ReviewModule from '../ReviewModule';
import './index.css';

const uuidv4 = require('uuid/v4');

export default (props) => (
  <StaticQuery
    query={graphql`
      query PopularArticles {
        allMarkdownRemark(filter: {fields: {popular: {eq: true}}}, sort: {fields: fields___popularity, order: DESC}, limit: 3) {
          edges {
            node {
              frontmatter {
                title
                date
                author
                tags
                teaserimg {
                  childImageSharp {
                    fluid(maxWidth: 1280) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                snippet
              }
              fields {
                slug
                type
              }
              excerpt
            }
          }
        }
      }
    `}
    render={(data) => <PopularArticles data={data} props={props} />}
  />
);

const PopularArticles = ({ data, props }) => {
  const { className, sideBar } = props;
  const sortedEdges = data.allMarkdownRemark.edges;
  return (
    <div className={`${className || ''}${sideBar ? ' reviews-sidebar' : ' reviews-full'}`}>
      <h3 className="reviews-header">Popular Articles</h3>
      <ul className="container">
        {
          sortedEdges.map((val) => {
            const reviewData = {
              slug: val.node.fields.slug,
              type: val.node.fields.type,
              sideBar,
              teaserimg: val.node.frontmatter.teaserimg.childImageSharp.fluid,
              title: val.node.frontmatter.title,
              tags: val.node.frontmatter.tags,
              snippet: val.node.frontmatter.snippet,
              date: val.node.frontmatter.date,
            };
            return (
              <ReviewModule key={uuidv4()} data={reviewData} />
            );
          })
        }
      </ul>
      <hr />
    </div>
  );
};

PopularArticles.defaultProps = {
  props: {},
  className: '',
  sideBar: false,
};

PopularArticles.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape({
          frontMatter: PropTypes.shape({
            title: PropTypes.string,
            date: PropTypes.string,
            author: PropTypes.string,
            tags: PropTypes.arrayOf(PropTypes.string),
            teaserimg: PropTypes.string,
            snippet: PropTypes.string,
          }),
          fields: PropTypes.shape({
            slug: PropTypes.string,
            type: PropTypes.string,
          }),
          excerpt: PropTypes.string,
        }),
      })),
    }),
  }).isRequired,
  props: PropTypes.shape({
    className: PropTypes.string,
    sideBar: PropTypes.bool,
  }),
  className: PropTypes.string,
  sideBar: PropTypes.bool,
};
