import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import uuidv4 from 'uuid/v4';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import RecentReviews from '../components/RecentReviews';
import PopularArticles from '../components/PopularArticles';
import RelatedArticles from '../components/RelatedArticles';
import './review.css';

const moment = require('moment');
const _ = require('lodash');

const Review = ({ data }) => {
  const post = data.markdownRemark;
  const date = moment(post.frontmatter.date).format('MMM Do YYYY');
  const { tags } = post.frontmatter;
  const allPages = data.allMarkdownRemark.edges;
  const sortedPages = [...allPages].sort((a, b) => {
    const aSim = tags.filter((tag) => a.node.frontmatter.tags.includes(tag)).length;
    const bSim = tags.filter((tag) => b.node.frontmatter.tags.includes(tag)).length;
    return bSim - aSim;
  });
  sortedPages.shift();

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        image={post.frontmatter.teaserimg.childImageSharp.fluid.src}
        description={post.frontmatter.snippet}
        twitterCard="summary_large_image"
      />
      <div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-3 mt-2">
              {`By: ${post.frontmatter.author}`}
              <br />
              {date}
              <br />
              Tags:
              {' '}
              {
                post.frontmatter.tags.map((item, ind) => (
                  <span key={uuidv4()}>
                    <Link to={`/tags/${_.kebabCase(item)}/`}>
                      {item}
                      {ind === post.frontmatter.tags.length - 1 ? '' : ', '}
                    </Link>
                  </span>
                ))
              }
              <hr />
              <h3>Table of Contents</h3>
              <div dangerouslySetInnerHTML={{ __html: post.tableOfContents }} />
              <hr />
              <RecentReviews className="d-none d-md-block" sideBar />
              <hr />
              <PopularArticles className="d-none d-md-block" sideBar />
              <RelatedArticles articles={sortedPages.slice(0, 3)} className="d-none d-md-block" sideBar />
            </div>
            <div className="col-sm-12 col-md-9">

              <h1>{post.frontmatter.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: post.html }} />

            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export const query = graphql`
  query($slug: String!, $tags: [String]) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      tableOfContents
      frontmatter {
        title
        tags
        author
        date
        snippet
        teaserimg {
          childImageSharp {
            fluid(maxWidth: 1280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(filter: {frontmatter: {tags: {in: $tags}}}) {
      edges {
        node {
          frontmatter {
            title
            date
            author
            tags
            teaserimg {
              childImageSharp {
                fluid(maxWidth: 1280) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            snippet
          }
          fields {
            slug
            type
          }
          excerpt
        }
      }
    }
  }
`;

export default Review;

Review.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      tableOfContents: PropTypes.string,
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        author: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string),
        date: PropTypes.string,
        snippet: PropTypes.string,
        teaserimg: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              src: PropTypes.string,
            }),
          }),
        }),
      }),
      html: PropTypes.string,
    }),
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape({
          frontMatter: PropTypes.shape({
            title: PropTypes.string,
            date: PropTypes.string,
            author: PropTypes.string,
            tags: PropTypes.arrayOf(PropTypes.string),
            teaserimg: PropTypes.string,
            snippet: PropTypes.string,
          }),
          fields: PropTypes.shape({
            slug: PropTypes.string,
            type: PropTypes.string,
          }),
          excerpt: PropTypes.string,
        }),
      })),
    }),
  }).isRequired,
};
