import React from 'react';
import PropTypes from 'prop-types';
import ReviewModule from '../ReviewModule';

const uuidv4 = require('uuid/v4');

const RelatedReviews = (props) => {
  const { className, sideBar, articles } = props;
  return (
    <div className={`${className || ''}${sideBar ? ' reviews-sidebar' : ' reviews-full'}`}>
      <h3 className="reviews-header">Similar Articles</h3>
      <ul className="container">
        {
          articles.map((val, ind) => {
            const reviewData = {
              slug: val.node.fields.slug,
              type: val.node.fields.type,
              sideBar,
              teaserimg: val.node.frontmatter.teaserimg.childImageSharp.fluid,
              title: val.node.frontmatter.title,
              tags: val.node.frontmatter.tags,
              snippet: val.node.frontmatter.snippet,
              date: val.node.frontmatter.date,
            };
            if (ind > 2 && sideBar) {
              return null;
            }
            return (
              <ReviewModule key={uuidv4()} data={reviewData} />
            );
          })
        }
      </ul>
      <hr />
    </div>
  );
};

export default RelatedReviews;

RelatedReviews.defaultProps = {
  className: '',
  sideBar: false,
  articles: [],
};

RelatedReviews.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  className: PropTypes.string,
  sideBar: PropTypes.bool,
};
